import React, { FunctionComponent } from 'react';
import { TipoUsuarioValues } from '../types';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { useHistory } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import '../css/trazabilidad.css';

const Trazabilidad: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  const router = useHistory();
  const btns: Array<{
    icon: JSX.Element;
    name: string;
    path: string;
    userTypeAccess: Array<TipoUsuarioValues>;
  }> = [
    {
      name: 'Administración',
      path: '/cobros/admin',
      userTypeAccess: [TipoUsuarioValues.Admin],
      icon: <AdminPanelSettingsIcon sx={{ fontSize: 70 }} />
    },
    {
      name: 'Merma',
      path: '/cobros/merma',
      userTypeAccess: [TipoUsuarioValues.Admin, TipoUsuarioValues.Operador],
      icon: <RvHookupIcon sx={{ fontSize: 70 }} />
    },
    {
      name: 'Faltante',
      path: '/cobros/faltante',
      userTypeAccess: [TipoUsuarioValues.Admin, TipoUsuarioValues.Operador],
      icon: <CurrencyExchangeIcon sx={{ fontSize: 60, marginRight: 1 }} />
    }
  ];

  return (
    <div className='trazabilidad-home-container'>
      {btns.map((b) =>
        b.userTypeAccess.includes(auth.user?.nombreTipoUsuario!) ? (
          <div className='trazabilidad-home-wrappper'>
            <button className='trazabilidad-home-button' onClick={() => router.push(b.path)}>
              {b.icon}
              {b.name}
            </button>{' '}
          </div>
        ) : null
      )}
    </div>
  );
};

export const TrazabilidadConnected = connect(
  ({ auth }: AppState) => ({ auth }),
  null
)(Trazabilidad);
