import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { TipoUsuarioValues } from '../types';
import { SidebarItem } from './App';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import {
  TreeItem2,
  TreeItem2Content,
  TreeItem2Label,
  TreeItem2Props
} from '@mui/x-tree-view/TreeItem2';
import GenericIcons from './generics/GenericIcons';
import { styled } from '@mui/material/styles';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { useHistory, useLocation } from 'react-router-dom';

interface CustomLabelProps {
  children: React.ReactNode;
  icon?: string;
}

interface CustomContentProps {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  ischild?: boolean;
}

interface CustomTreeItemProps extends TreeItem2Props {
  icon?: string;
  path?: string;
  ischild?: boolean;
}

type Props = {
  auth: AuthState;
  sideBarItems: Array<SidebarItem>;
};

const AppNavbar: FunctionComponent<Props> = ({ auth, sideBarItems }) => {
  const { isAuthenticated, user } = auth;
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState<string | string[] | null>(null);

  useEffect(() => {
    const findLocation = getItemId(sideBarItems, []).find(
      (item) => item.path === location.pathname
    );
    if (findLocation) {
      setSelectedItems([findLocation.itemId, ...findLocation.parentIds]);
    } else {
      setSelectedItems(null);
    }
  }, [location]);

  return isAuthenticated &&
    [
      TipoUsuarioValues.Admin as string,
      TipoUsuarioValues.Operador as string,
      TipoUsuarioValues.Visualizador as string
    ].includes(user!.nombreTipoUsuario) ? (
    <CustomSimpleTreeView selectedItems={selectedItems} multiSelect>
      {renderSideBarItem(sideBarItems, 1)}
    </CustomSimpleTreeView>
  ) : null;
};

export default connect(({ auth }: AppState) => ({ auth }))(AppNavbar);

const getItemId = (
  items: Array<SidebarItem>,
  parentIds: Array<string> = []
): Array<{
  itemId: string;
  path: string;
  parentIds: Array<string>;
}> => {
  let res: Array<{
    itemId: string;
    path: string;
    parentIds: Array<string>;
  }> = [];
  items.forEach((item) => {
    const currentParentIds = [...parentIds, item.itemId];
    if (item.childOrComponent) {
      if (Array.isArray(item.childOrComponent)) {
        res = res.concat(getItemId(item.childOrComponent, currentParentIds));
      } else if (
        'path' in item.childOrComponent &&
        'component' in item.childOrComponent &&
        !item.disabled
      ) {
        res.push({ itemId: item.itemId, path: item.childOrComponent.path, parentIds });
      }
    }
  });
  return res;
};

const CustomSimpleTreeView = styled(SimpleTreeView)(() => ({
  width: '100%'
}));

const renderSideBarItem = (sideBarItems: Array<SidebarItem>, level: number) => {
  const CustomContent = ({ disabled, selected, ischild, ...other }: CustomContentProps) => {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={
            selected && ischild
              ? {
                  position: 'absolute',
                  height: '60%',
                  width: '3px',
                  top: '20%',
                  left: '-15px',
                  backgroundColor: '#78A434',
                  borderRadius: '3px'
                }
              : undefined
          }></div>
        <TreeItem2Content
          {...other}
          status={{
            expandable: true,
            expanded: false,
            focused: false,
            selected: false,
            disabled: !!disabled,
            editing: false,
            editable: false
          }}
        />
      </div>
    );
  };

  const CustomLabel = ({ icon, ...other }: CustomLabelProps) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          height: '20px'
        }}>
        {icon ? <GenericIcons icon={icon} imageStyle={{ height: '100%', margin: 0 }} /> : null}
        <TreeItem2Label {...other} />
      </div>
    );
  };

  const CustomStyleTreeItem = styled(TreeItem2)<CustomTreeItemProps>(({ path, ischild }) => {
    return {
      color: '#303335',
      width: '100%',
      [`& .${treeItemClasses.content}`]: {
        padding: '4px',
        gap: '5px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
      },
      [`& .${treeItemClasses.iconContainer}`]: {
        width: '15px',
        [`& .MuiSvgIcon-root`]: {
          width: '15px',
          height: '15px',
          aspectRatio: '1/1'
        }
      },
      [`& .${treeItemClasses.selected}`]:
        path && ischild
          ? {
              backgroundColor: 'transparent',
              [`& .${treeItemClasses.label}`]: {
                fontWeight: 'bold',
                color: '#78A434'
              }
            }
          : {
              backgroundColor: '#f3f3f3',
              borderRadius: '5px',
              [`& .${treeItemClasses.label}`]: {
                fontWeight: 'normal'
              }
            },
      [`& .${treeItemClasses.groupTransition}`]: {
        padding: '0 0 0 25px'
      }
    };
  });

  const CustomTreeItem = React.forwardRef<HTMLLIElement, CustomTreeItemProps>((props, ref) => {
    const { icon, path, ischild, disabled, ...other } = props;
    const router = useHistory();
    const location = useLocation();

    const selected = location.pathname === path;

    return (
      <CustomStyleTreeItem
        disabled={disabled}
        onClick={path && !disabled ? () => router.push(path) : undefined}
        {...other}
        ref={ref}
        path={path}
        ischild={ischild}
        slots={{ label: CustomLabel, content: CustomContent }}
        slotProps={{
          label: { icon } as any,
          content: { disabled, ischild, selected } as any
        }}
      />
    );
  });

  return sideBarItems.map((item, idx) => {
    if (Array.isArray(item.childOrComponent)) {
      return (
        <CustomTreeItem
          key={`sidebar-item-${idx}`}
          itemId={item.itemId}
          label={item.name}
          icon={item.icon}
          disabled={item.disabled}
          ischild={level !== 1}>
          {renderSideBarItem(item.childOrComponent, level + 1)}
        </CustomTreeItem>
      );
    } else if (typeof item.childOrComponent === 'object') {
      return (
        <CustomTreeItem
          key={`sidebar-item-${idx}`}
          itemId={item.itemId}
          label={item.name}
          icon={item.icon}
          path={item.childOrComponent.path}
          disabled={item.disabled}
          ischild={level !== 1}
        />
      );
    } else return null;
  });
};
