import React, { FunctionComponent, useEffect, useState } from 'react';
import InputField from './InputField';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectInput, { SelectDataType } from './SelectInput';

const TrazabilidadMultiForm: FunctionComponent<{
  skuData: Array<{
    id: string;
    name: string;
    description: string;
  }>;
  isLoading: boolean;
}> = ({ skuData, isLoading }) => {
  const [data, setData] = useState<
    Array<{
      sku: SelectDataType | null;
      cantidad: string;
      descripcion: string;
      error?: boolean;
    }>
  >([{ sku: null, cantidad: '', descripcion: '' }]);

  useEffect(() => {
    setData([{ sku: null, cantidad: '', descripcion: '' }]);
  }, [skuData]);

  const handleAddRow = () => {
    setData([...data, { sku: null, cantidad: '', descripcion: '' }]);
  };

  const handleRemoveRow = (index: number) => {
    if (data.length > 1) setData(data.filter((_, i) => i !== index));
  };

  const handleSkuChange = async (index: number, value: SelectDataType | null) => {
    const updatedData = [...data];

    updatedData[index].error = false;

    const alreadySelected = data.find((d) => d.sku?.value === value?.value);

    if (alreadySelected) {
      updatedData[index].sku = null;
      updatedData[index].descripcion = '';
      updatedData[index].cantidad = '';
      updatedData[index].error = true;
      setData(updatedData);
      return;
    }

    updatedData[index].sku = value;

    const findSku = skuData.find((s) => s.id === value?.value);
    const description = findSku?.description || '';

    updatedData[index].descripcion = description;
    updatedData[index].cantidad = '';

    setData(updatedData);
  };

  const handleCantidadChange = (index: number, value: string) => {
    const updatedData = [...data];
    updatedData[index].cantidad = value;
    setData(updatedData);
  };

  return (
    <div className='trazabilidad-multi-form'>
      <div>
        <Button
          variant='contained'
          color='success'
          onClick={() => handleAddRow()}
          disabled={isLoading}>
          Nueva fila
        </Button>
      </div>

      {data.map((d, idx) => (
        <div key={idx} className='trazabilidad-multi-form-row'>
          <div className='trazabilidad-multi-form-row-fields-container'>
            <SelectInput
              label='SKU'
              value={d.sku}
              onChange={(_, newValue) => {
                handleSkuChange(idx, newValue);
              }}
              options={skuData.map((s) => ({ name: s.name, value: s.id }))}
              error={d.error}
              disabled={isLoading}
              required
            />
            <InputField
              label='Descripción'
              value={d.descripcion}
              variant='standard'
              disabled={isLoading}
              readOnly
              fullWidth
            />
            <InputField
              label='Cantidad'
              value={d.cantidad}
              type='number'
              handleInputChange={(e) => handleCantidadChange(idx, e.target.value)}
              disabled={isLoading}
              minValue={1}
              fullWidth
              required
            />
          </div>
          <div className='trazabilidad-multi-form-row-action-container'>
            <div>
              <IconButton
                aria-label='delete'
                color='error'
                onClick={() => handleRemoveRow(idx)}
                disabled={isLoading}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrazabilidadMultiForm;
