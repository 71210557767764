import React, { FunctionComponent, SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason
} from '@mui/material/Autocomplete';

export type SelectDataType = { value: string | number; name: string };

const SelectInput: FunctionComponent<{
  label: string;
  value: SelectDataType | null;
  onChange: (
    event: SyntheticEvent,
    value: SelectDataType | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<SelectDataType>
  ) => void;
  options: Array<SelectDataType>;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
}> = ({ label, value, onChange, options, error, required, disabled }) => {
  return (
    <Autocomplete
      color='error'
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText='No se encontraron opciones'
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          color={error ? 'error' : 'primary'}
          required={required}
        />
      )}
    />
  );
};

export default SelectInput;
