import React, { FunctionComponent } from 'react';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { CobroAdminCRUD } from '../new-table/components/GeneratedComponents';
import BackButton from '../new-table/components/BackButton';

const TrazabilidadAdmin: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  return (
    <div className='trazabilidad-container'>
      <div className='trazabilidad-title-container'>
        <BackButton route='/cobros' />
      </div>
      <CobroAdminCRUD />
    </div>
  );
};

export const TrazabilidadAdminConnected = connect(
  ({ auth }: AppState) => ({ auth }),
  null
)(TrazabilidadAdmin);
