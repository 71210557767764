import React, {
  CSSProperties,
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import Modal from '@mui/material/Modal';
import GenericIcons from './generics/GenericIcons';
import InputField from './InputField';
import SelectInput, { SelectDataType } from './SelectInput';
import { FileUploader } from 'react-drag-drop-files';
import TrazabilidadMultiForm from './TrazabilidadMultiForm';
import { Alert, Button } from '@mui/material';
import { getUncatchEndpointData } from '../utils/request';
import { IdTipoDocumentoValues } from '../types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/es';

const TrazabilidadForm: FunctionComponent<{ setIsLoading: Dispatch<SetStateAction<boolean>> }> = ({
  setIsLoading
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [oca, setOca] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [detalle, setDetalle] = useState<string>('');
  const [cdOrigen, setCdOrigen] = useState<Array<SelectDataType>>([]);
  const [selectedCdOrigen, setSelectedCdOrigen] = useState<SelectDataType | null>(null);
  const [cdDestino, setCdDestino] = useState<Array<SelectDataType>>([]);
  const [selectedCdDestino, setSelectedCdDestino] = useState<SelectDataType | null>(null);
  const [eett, setEett] = useState<Array<SelectDataType>>([]);
  const [selectedEett, setSelectedEett] = useState<SelectDataType | null>(null);
  const [skuData, setSkuData] = useState<
    Array<{
      id: string;
      name: string;
      description: string;
    }>
  >([]);
  const [files, setFiles] = useState<File[] | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => setIsLoading(loading), [loading]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resAllCd = await getUncatchEndpointData({ endpoint: 'centroInventario/zonas/all' });
      if (resAllCd && Array.isArray(resAllCd)) {
        setCdOrigen(resAllCd.map((row) => ({ value: row?.nombreCentro, name: row?.nombreCentro })));
      }
      const resUserCd = await getUncatchEndpointData({ endpoint: 'centroInventario/zonas' });
      if (resUserCd && Array.isArray(resUserCd)) {
        setCdDestino(
          resUserCd.map((row) => ({ value: row?.nombreCentro, name: row?.nombreCentro }))
        );
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (selectedCdDestino) {
        const resSku = await getUncatchEndpointData({
          endpoint: 'sku/getByNombreCentro',
          query: {
            nombreCentro: selectedCdDestino.value,
            idTipoActivo: IdTipoDocumentoValues.idConteoCriticidad
          }
        });
        if (resSku && Array.isArray(resSku)) {
          setSkuData(
            resSku.map((row) => ({
              id: row?.id,
              name: `${row?.material} (Centro: ${row?.centro})`,
              description: row?.textoBreveMaterial
            }))
          );
        }
      } else {
        setSkuData([]);
      }
      setLoading(false);
    })();
  }, [selectedCdDestino]);

  const handleChange = (newFiles: File[] | null) => {
    if (!newFiles) {
      setError('');
      return;
    }

    if ((files && files.length + newFiles.length > 5) || newFiles.length > 5) {
      setError('Solo puedes subir un máximo de 5 archivos.');
      return;
    }
    for (const file of newFiles) {
      if (file.size > 5 * 1024 * 1024) {
        setError(`El archivo ${file.name} excede el tamaño máximo de 5 MB.`);
        return;
      }
    }

    setFiles((prevFiles) => {
      if (!prevFiles) return [...newFiles];
      return [...prevFiles, ...newFiles];
    });
    setError('');
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => {
      if (prevFiles) {
        const res = prevFiles.filter((_, i) => i !== index);
        if (res.length === 0) return null;
        return res;
      }
      return null;
    });
  };

  return (
    <div className='trazabilidad-form-container'>
      <SelectInput
        label='Selecciona CD Origen'
        value={selectedCdOrigen}
        onChange={(_, newValue) => {
          setSelectedCdOrigen(newValue);
        }}
        options={cdOrigen}
        disabled={loading}
        required
      />

      <SelectInput
        label='Selecciona CD Destino'
        value={selectedCdDestino}
        onChange={(_, newValue) => {
          setSelectedCdDestino(newValue);
        }}
        options={cdDestino}
        disabled={loading}
        required
      />

      <SelectInput
        label='Selecciona una EETT'
        value={selectedEett}
        onChange={(_, newValue) => {
          setSelectedEett(newValue);
        }}
        options={eett}
        disabled={loading}
        required
      />

      <InputField
        label='OCA'
        value={oca}
        type='text'
        handleInputChange={(e) => setOca((e.target.value || '').toUpperCase())}
        maxLength={14}
        disabled={loading}
        fullWidth
        required
      />

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          label='Fecha OCA'
          views={['year', 'month', 'day']}
          openTo='day'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          slotProps={{
            field: { clearable: true, format: 'DD/MM/YYYY' }
          }}
        />
      </LocalizationProvider>

      <InputField
        label='Detalle'
        value={detalle}
        type='text'
        handleInputChange={(e) => setDetalle(e.target.value)}
        fullWidth
        disabled={loading}
        required
      />

      <div className='trazabilidad-file-uploader-container'>
        <FileUploader
          classes='trazabilidad-file-uploader'
          handleChange={handleChange}
          name='file'
          label='Cargue o arrastre los PDF aquí'
          hoverTitle='Soltar aquí'
          types={['pdf']}
          fileOrFiles={files}
          disabled={loading}
          multiple
          required
        />
        {error && <Alert severity='warning'>{error}</Alert>}
        {files && files.length > 0 && (
          <div className='trazabilidad-file-uploader-file-list-container'>
            <h3 className='trazabilidad-file-uploader-file-list-title'>Archivos cargados:</h3>

            {files.map((file, idx) => (
              <p key={idx} className='trazabilidad-file-uploader-file-list-item'>
                <span
                  className='trazabilidad-file-uploader-file-list-item-remove'
                  onClick={() => handleRemoveFile(idx)}>
                  X
                </span>{' '}
                {file.name}
              </p>
            ))}
          </div>
        )}
      </div>

      <TrazabilidadMultiForm skuData={skuData} isLoading={loading} />
    </div>
  );
};

const TrazabilidadModalForm: FunctionComponent<{ title: string }> = ({ title }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <div>
        <Button onClick={handleOpen} variant='contained' color='success'>
          Agregar
        </Button>
      </div>

      <Modal open={open} onClose={handleClose}>
        <div style={Styles.modalContainer}>
          <div style={Styles.modalContent}>
            <div style={Styles.closeContainer}>
              <h2 style={Styles.title}>{title}</h2>
              <GenericIcons
                icon='closeIcon'
                imageStyle={{ height: '15px', cursor: 'pointer' }}
                onClick={() => handleClose()}
              />
            </div>

            <TrazabilidadForm setIsLoading={setIsLoading} />

            <div style={Styles.actionBtn}>
              <Button variant='contained' color='primary' disabled={isLoading}>
                Finalizar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default TrazabilidadModalForm;

const Styles: { [key: string]: CSSProperties } = {
  modalContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: '#f3f3f3',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: '80%',
    height: '90%',
    padding: '20px',
    borderRadius: '10px'
  },
  modalContent: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px'
  },
  closeContainer: {
    width: '100%',
    height: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333',
    textTransform: 'uppercase',
    margin: 0
  },
  actionBtn: {
    height: '8%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
};
