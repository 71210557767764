import React, { FunctionComponent, useMemo } from 'react';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import BackButton from '../new-table/components/BackButton';
import { CobroCRUD } from '../new-table/components/GeneratedComponents';

const TrazabilidadMerma: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  const CRUD = useMemo(() => CobroCRUD('Merma'), []);
  return (
    <div className='trazabilidad-container'>
      <div className='trazabilidad-title-container'>
        <BackButton route='/cobros' />
      </div>

      <CRUD />
    </div>
  );
};

export const TrazabilidadMermaConnected = connect(
  ({ auth }: AppState) => ({ auth }),
  null
)(TrazabilidadMerma);
