import { CobroAdmin } from '../../types';
import { WebEntity } from './types';

const CobroAdminEntity: WebEntity<CobroAdmin> = {
  name: 'usuario',
  endpoint: 'cobros',
  referenceColumn: 'oca',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'OCA',
      accessorKey: 'oca',
      columnType: 'text'
    },
    {
      header: 'SKU',
      accessorKey: 'sku',
      columnType: 'text'
    },
    {
      header: 'Descripcion SKU',
      accessorKey: 'descripcionSku',
      columnType: 'text'
    },
    {
      header: 'Transporte',
      accessorKey: 'transporte',
      columnType: 'text'
    },
    {
      header: 'Cantidad de cajas',
      accessorKey: 'cantidadCajas',
      columnType: 'text'
    },
    {
      header: 'Detalle del cobro',
      accessorKey: 'detalleCobro',
      columnType: 'text'
    },
    {
      header: 'Evidencia',
      accessorKey: 'evidencia',
      columnType: 'text'
    },
    {
      header: 'Estado',
      accessorKey: 'estado',
      columnType: 'text'
    },
    {
      header: 'ID Carta Cobranza',
      accessorKey: 'idCobranza',
      columnType: 'text'
    },
    {
      header: 'Precio Costo',
      accessorKey: 'precioCosto',
      columnType: 'text'
    }
  ]
};

export default CobroAdminEntity;
