import React, { CSSProperties, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { ColumnType, WebEntity, WebEntityName } from '../../entities/types';
import { AuthState, EntitiesState, ErrorState } from '../../reducers/types';
import { BasicEntity, TipoUsuarioValues } from '../../types';
import { capitalize } from '../../utils/utils';
import {
  AdditionalTableActionsFunc,
  AdditionalTableProps,
  ColumnComponent,
  CRUDAllowedActions
} from '../types';
import GenericModalForm from './GenericModalForm';
import GenericModalMultiInputForm from './GenericModalMultiInputForm';
import GenericTable from './GenericTable';
import GenericIcons from './GenericIcons';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

type Props<T> = {
  // Global States
  entities: EntitiesState;
  error: ErrorState;
  auth: AuthState;

  // Redux Actions
  getEntities: (pageNumber?: number, pageSize?: number, filters?: any) => Promise<any>;
  selectedEntities: (selected: T[]) => any;
  singleSelectedEntity: <T extends BasicEntity>(selected: T) => any;
  deleteEntity: (id: string[]) => any;
  addEntity: (entity: T | T[]) => any;
  multiAddEntity: (entity: T | T[]) => Promise<any>;
  editEntity: (entity: T) => any;
  notifyMessageEntity: (msg: string) => void;

  // Aditional
  webEntity: WebEntity<T>;
  title: string;
  signalsToWatch: string[];
  allowedActions?: Partial<CRUDAllowedActions<T>>;
  serverSidePagination?: boolean;
  additionalTableProps?: AdditionalTableProps<T>;
  additionalTableActions?: AdditionalTableActionsFunc<T>;
  columnComponent?: ColumnComponent<T>;
  preProcessEntityList?: (entityList: Array<any>) => Array<any>;
  sharedFilterName?: string;
  customExport?: {
    exportDataHandler: (when?: string) => Promise<any[]>;
    columnTypes: ColumnType[];
    fileName?: string;
  };
  liveFilter?: boolean;

  // Styles General
  style?: CSSProperties;
  headerScroll?: boolean;
  headerScrollHeight?: string;
  viewInputFilter?: boolean;
};

const GenericCRUD = <T,>({
  entities,
  error,
  auth,
  getEntities,
  selectedEntities,
  singleSelectedEntity,
  deleteEntity,
  addEntity,
  multiAddEntity,
  editEntity,
  notifyMessageEntity,
  webEntity,
  title,
  signalsToWatch,
  allowedActions,
  serverSidePagination,
  additionalTableProps,
  additionalTableActions,
  columnComponent,
  preProcessEntityList,
  sharedFilterName,
  customExport,
  liveFilter,
  style,
  headerScroll,
  headerScrollHeight,
  viewInputFilter
}: Props<T>) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const {
    list: entityList,
    loading,
    notificationMessage
  } = entities[webEntity.name as WebEntityName];

  const [forceRefresh, setForceRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setErrorMsg(error.msg.msg);
      setTimeout(() => setErrorMsg(''), 5000);
    }

    if (notificationMessage) {
      setTimeout(() => notifyMessageEntity(''), 5000);
    }
  }, [error, notificationMessage, notifyMessageEntity]);

  const { isAuthenticated, user } = auth;
  const isAdmin = user?.nombreTipoUsuario === TipoUsuarioValues.Admin;

  const onAddEntity = () => async (fields: T | T[]) => {
    toast.promise(
      async () => {
        const res = await addEntity(fields);
        if (res && res.status === 200) {
          return true;
        } else {
          throw error;
        }
      },
      {
        pending: 'Cargando...',
        success: `Se insertó correctamente el registro`,
        error: 'Error, no se pudo insertar el registro'
      }
    );
  };

  const onMultiAddEntity = () => async (fields: T | T[]) => {
    toast.promise(
      async () => {
        const res = await multiAddEntity(fields);
        if (res && res.status === 200) {
          return res;
        } else {
          throw error;
        }
      },
      {
        pending: 'Cargando...',
        success: `Se insertaron correctamente los registros`,
        error: 'Error, no se pudieron insertar los registros'
      }
    );
  };

  const onDeleteEntity = (getSelectedData: () => { ids: string[]; refs: string[] }) => async () => {
    const { ids, refs } = getSelectedData();

    Swal.fire({
      icon: 'question',
      title: `¿Seguro que deseas eliminar: \r ${refs}?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.promise(
          async () => {
            const res = await deleteEntity(ids);
            if (res) {
              notifyMessageEntity(`Se han elimando correctamente los siguientes datos: ${res}`);
              if (serverSidePagination) {
                setForceRefresh(!forceRefresh);
              }
              return res;
            } else {
              notifyMessageEntity(`La accion no se ha completado correctamente`);
              throw error;
            }
          },
          {
            pending: 'Cargando...',
            success: {
              render({ data }) {
                return `Se elimino correctamente el registro: ${data}`;
              }
            },
            error: 'Error, no se puede eliminar el registro'
          }
        );
      }
    });
  };

  const onEditEntity = (row: BasicEntity) => async (updatedEntity: T) => {
    Swal.fire({
      icon: 'question',
      title: `¿Seguro que deseas continuar con la edición?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.promise(
          async () => {
            const res = await editEntity({ _id: row._id, ...updatedEntity });
            if (res) {
              return res;
            } else {
              throw error;
            }
          },
          {
            pending: 'Cargando...',
            success: {
              render({ data }) {
                const referenceColumn = data.hasOwnProperty(webEntity?.referenceColumn)
                  ? data[webEntity.referenceColumn]
                  : row._id;
                return `Se editó correctamente el registro: ${referenceColumn}`;
              }
            },
            error: 'Error, no se pudo editar el registro'
          }
        );
      }
    });
  };

  const renderAddButton = (): JSX.Element => (
    <GenericModalForm
      webEntity={webEntity}
      actionFn={onAddEntity()}
      errorMsg={errorMsg}
      isAuthenticated={isAuthenticated}
      isAdmin={isAdmin}
    />
  );

  const renderAddMultiLineButton = (): JSX.Element => (
    <GenericModalMultiInputForm
      webEntity={webEntity}
      actionFn={onMultiAddEntity()}
      errorMsg={errorMsg}
      isAuthenticated={isAuthenticated}
      isAdmin={isAdmin}
    />
  );

  const selectedSingleEntity: <T extends BasicEntity>(row: T, event: React.MouseEvent) => any = (
    row,
    _
  ) => {
    singleSelectedEntity(row);
  };

  const renderEditButton = (row: BasicEntity): JSX.Element => (
    <GenericModalForm
      webEntity={webEntity}
      errorMsg={errorMsg}
      isAuthenticated={isAuthenticated}
      isAdmin={isAdmin}
      actionFn={onEditEntity(row)}
      alternativeButton={(toggle: () => void) => (
        <div onClick={toggle} style={{ display: 'flex' }}>
          <GenericIcons
            icon='edit'
            imageStyle={{
              width: '32px',
              height: ' 32px'
            }}
          />
        </div>
      )}
      entityToEdit={row}
      title={`Editar ${webEntity.name}`}
      actionName={'Editar'}
    />
  );

  const renderDeleteButton =
    () =>
    (getSelectedData: () => { ids: string[]; refs: string[] }): JSX.Element =>
      (
        <button
          key='delete'
          className='btn-delete-general'
          onClick={onDeleteEntity(getSelectedData)}>
          <GenericIcons icon='trashWhite' />
          Eliminar
        </button>
      );

  const preProcessedEntityList = preProcessEntityList
    ? preProcessEntityList(entityList)
    : entityList;

  const allowedActionSelect = {
    ...{
      enable: false,
      single: false,
      pageOnly: false,
      rowDisabledCriteria: undefined
    },
    ...allowedActions?.select
  };

  const allowedTableActions = {
    add: isAuthenticated && allowedActions?.add,
    multiLineForm: isAuthenticated && allowedActions?.multiLineForm,
    delete: isAuthenticated && allowedActions?.delete,
    edit: isAuthenticated && allowedActions?.edit,
    export: isAuthenticated && allowedActions?.export,
    select: isAuthenticated && allowedActionSelect.enable,
    singleSelect: isAuthenticated && allowedActionSelect.single
  };

  const localColumnComponent =
    columnComponent !== undefined && allowedTableActions.edit
      ? ({
          ...columnComponent,
          column: {
            ...columnComponent.column,
            cell: (row: T) => (
              <>
                {renderEditButton(row as unknown as BasicEntity)}
                {columnComponent.column.cell(row)}
              </>
            )
          }
        } as unknown as ColumnComponent<T>)
      : !columnComponent && allowedTableActions.edit
      ? ({
          begin: false,
          column: {
            cell: renderEditButton,
            allowOverflow: true,
            button: true
          }
        } as unknown as ColumnComponent<T>)
      : columnComponent && !allowedTableActions.edit
      ? columnComponent
      : undefined;

  return (
    <div style={style ? style : { padding: ' 20px 15px' }}>
      {errorMsg && signalsToWatch.includes(error.id!) && (
        <Alert color='danger' fade={false} style={{ marginTop: 15 }}>
          {errorMsg}
        </Alert>
      )}

      {notificationMessage && (
        <Alert color='info' fade={false} style={{ marginTop: 15 }}>
          {notificationMessage}
        </Alert>
      )}

      {loading && (
        <Alert color='warning' fade={false}>
          Cargando...
        </Alert>
      )}

      <GenericTable
        columns={webEntity.tableColumns}
        tableData={preProcessedEntityList}
        fetchData={getEntities}
        filterFields={webEntity.filterFields}
        addAction={renderAddButton()}
        addMultiLineAction={renderAddMultiLineButton()}
        deleteAction={renderDeleteButton}
        allowedActions={allowedTableActions}
        onSelectedRowsChange={selectedEntities}
        liveFilter={liveFilter}
        sharedFilterName={sharedFilterName}
        tableName={capitalize(title)}
        serverSidePagination={serverSidePagination}
        selectablePageOnly={allowedActionSelect.pageOnly}
        rowDisabledCriteria={allowedActionSelect.rowDisabledCriteria}
        rowColumnReference={webEntity.referenceColumn}
        secondaryAction={additionalTableActions}
        columnComponent={localColumnComponent}
        additionalTableProps={additionalTableProps}
        customExport={customExport}
        selectedSingleEntity={selectedSingleEntity}
        isAdmin={isAdmin}
        fixedHeaderScrollHeight={headerScrollHeight}
        fixedHeader={headerScroll}
        forceRefresh={forceRefresh}
        ViewInput={viewInputFilter}
      />
    </div>
  );
};

export default GenericCRUD;
