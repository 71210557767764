import React from 'react';
import * as E from '../entities';
import { generateCRUD } from './CRUDGenerator';
import TrazabilidadModalForm from '../../components/TrazabilidadModalForm';

// MANTENEDORES ============================================
export const CobroCRUD = (title: string) =>
  generateCRUD({
    webEntity: E.CobroEntity,
    title,
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false
    },
    initialState: {
      columnVisibility: {
        _id: false
      },
      columnPinning: {
        right: ['action']
      }
    },
    additionalTopTableActions: [
      {
        component: <TrazabilidadModalForm title={`Agregar ${title}`} />
      }
    ]
  });

export const CobroEETTCRUD = (title: string) =>
  generateCRUD({
    webEntity: E.CobroEETTEntity,
    title,
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false
    },
    initialState: {
      columnVisibility: {
        _id: false
      }
    }
  });

export const CobroAdminCRUD = generateCRUD({
  webEntity: E.CobroAdminEntity,
  title: 'Administración',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false
  },
  initialState: {
    columnVisibility: {
      _id: false
    }
  }
});
