import React, { ChangeEventHandler, FunctionComponent } from 'react';
import { TextField, Typography } from '@mui/material';

const InputField: FunctionComponent<{
  label: string;
  value: string;
  handleInputChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type?: 'text' | 'number';
  variant?: 'outlined' | 'standard' | 'filled';
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  required?: boolean;
}> = ({
  label,
  value,
  handleInputChange,
  type,
  variant = 'outlined',
  maxLength,
  minValue,
  maxValue,
  disabled = false,
  readOnly = false,
  fullWidth = false,
  required
}) => {
  return (
    <div>
      <TextField
        label={label}
        variant={variant}
        value={value}
        type={type}
        onChange={handleInputChange}
        disabled={disabled}
        fullWidth={fullWidth}
        required={required}
        inputProps={{ maxLength: maxLength, min: minValue, max: maxValue, readOnly }}
      />
      {type === 'text' && maxLength && (
        <Typography variant='body2' color='textSecondary' align='left'>
          {value.length}/{maxLength} caracteres
        </Typography>
      )}
    </div>
  );
};

export default InputField;
